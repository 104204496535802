import React from 'react'
import { TreeNode, createBehavior, createResource, SelectNodeEvent } from '@designable/core'
import {
  DnFC,
  useTreeNode,
  useNodeIdProps,
  DroppableWidget,
  useDesigner
} from '@designable/react'
import { observer } from '@formily/reactive-react'
import { LoadTemplate } from '../../common/LoadTemplate'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import './styles.less'
import { uid } from '@formily/shared'
import { useEffect } from 'react'
import { useState } from 'react'
import classnames from 'classnames'

// type formilyGrid = typeof FormilyGird
interface IdesignableGridProps {
  columns: any
  rowMargin: any
  columnMargin: any
  GridColumn: any
  style: any
  children: any
}

export const Grid: DnFC<IdesignableGridProps> & {
  GridColumn?: React.FC<any>
} = observer((props) => {
  const node = useTreeNode()
  const nodeId = useNodeIdProps()
  const engine = useDesigner()
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    return engine.subscribeTo(SelectNodeEvent, (event) => {
      setSelected(false)
      const source = event.data?.source;
      // @ts-ignore
      if (source && source?.length > 0) {
        if (source[0].id === node.id) {
          setSelected(true)
        }
      }
    })
  }, [])
  // const gridStyle = {};
  //   gridStyle['gridTemplateColumns'] = columns || undefined;
  //   gridStyle['gridRowGap'] = rowMargin;
  //   gridStyle['gridColumnGap'] = columnMargin;
  if (node.children.length === 0) { 
    return (
      <div {...props}>
        <DroppableWidget />
        <LoadTemplate
          actions={[
            {
              title: node.getMessage('addGridColumn'),
              icon: 'AddColumn',
              onClick: () => {
                const column = new TreeNode({
                  componentName: 'Field',
                  props: {
                    type: 'void',
                    'x-component': 'Grid.GridColumn',
                  },
                })
                node.append(column)
              },
            },
          ]}
        />
      </div>
    )

  }

  return (
    <div {...nodeId}  className={classnames("dn-layout-grid", {'dn-grid-selected': selected})} >
      {/* <div {...props} 
        key={uid()}
        style={{
            ...props.style,
            ...gridStyle,
            display: "grid",
          }}>{props.children}</div> */}
      <LayoutGrid {...props} >{props.children}</LayoutGrid>
      <div className='dn-grid-load-action'>
      <LoadTemplate
        actions={[
          {
            title: node.getMessage('addGridColumn'),
            icon: 'AddColumn',
            onClick: () => {
              const column = new TreeNode({
                componentName: 'Field',
                props: {
                  type: 'void',
                  'x-component': 'Grid.GridColumn',
                },
              })
              node.append(column)
            },
          },
        ]}
      />
      </div>
    </div>
  )
})

Grid.GridColumn = observer(({ ...props }) => {
  return (
    <DroppableWidget {...props} className='grid-layout-item'>
      {props.children}
    </DroppableWidget>
  )
})

Grid.Behavior = createBehavior(
  {
    name: 'Grid',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Grid',
    designerProps: {
      droppable: true,
      allowDrop: (node) => node.props['x-component'] !== 'Grid',
      propsSchema: createBaseSchema(AllSchemas.Grid),
    },
    designerLocales: AllLocales.Grid,
  },
  {
    name: 'Grid.GridColumn',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Grid.GridColumn',
    designerProps: {
      droppable: true,
      resizeXPath: 'x-component-props.gridSpan',
      resizeStep: 1,
      resizeMin: 1,
      resizeMax: 12,
      allowDrop: (node) => node.props['x-component'] === 'Grid',
      propsSchema: createBaseSchema(AllSchemas.Grid.GridColumn),
    },
    designerLocales: AllLocales.GridColumn,
  }
)

Grid.Resource = createResource({
  icon: 'LayoutGridSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Grid',
        'x-component-props': {
          columns: 'auto auto auto',
          rowMargin: '10px',
          columnMargin: '10px'
        }
      },
      children: [
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'Grid.GridColumn',
          },
        },
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'Grid.GridColumn',
          },
        },
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'Grid.GridColumn',
          },
        },
      ],
    },
  ],
})


export const createGrid = observer((gridProps : any) => {
  const {columns, rowMargin, columnMargin, children, ...props} = gridProps
  console.log(gridProps)
  const gridStyle = {};
    gridStyle['gridTemplateColumns'] = columns || undefined;
    gridStyle['gridRowGap'] = rowMargin;
    gridStyle['gridColumnGap'] = columnMargin;
  return <div className="dn-layout-grid">
    <div {...props} 
      key={uid()}
      style={{
          ...props.style,
          ...gridStyle,
          display: "grid",
        }}>
          { children }
    </div>
  </div>
})

const GridColumn = observer(({ children, ...props }) => {
  return (
    <div {...props} className='grid-layout-item'>{children}</div>
  )
})

export const LayoutGrid = Object.assign(createGrid, {
  GridColumn,
})